<template>
	<div>
		<searchCard>
			<div class="flex-right">
				<b-button class="block px-10" @click="">수정</b-button>
				<b-button class="block mt-4 px-10" @click="" variant="gray">취소</b-button>
			</div>
		</searchCard>
	</div>
</template>

<script>

const now = new Date(),
	claimOpts = [
		{ label: '전체', value: 'All' },
		{ label: 'Y', value: 'Exist' },
		{ label: 'N', value: 'None' },
	],
	//딜 요청 상태
	showOpts = [
		{ label: '전체', value: 'All' },
		{ label: '노출', value: 'Show' },
		{ label: '미노출', value: 'Hide' },
	],
	keywordOpts = [
		{ label: '주문번호', value: 'orderNum', operation: 'equal' },
		{ label: '상품그룹 idx', value: 'productGroupIdx', operation: 'equal' },
		{ label: '파트너사', value: 'companyName' },
		{ label: '브랜드', value: 'brandName' },
		{ label: '상품명', value: 'productGroupName' },
		{ label: '핫트아이디', value: 'hottId' },
	],
	defOpt = {
		period: 'period',
		startDate: getMonth( now, -1 ),
		endDate: now,

		claimStatus: claimOpts[ 0 ],
		showStatus: showOpts[ 0 ],
		searchKeyName: keywordOpts[ 0 ],
		searchKeyword: '',
	}

export default {
	data() {
		return {
			inf: [
				{ title: 'No.', model: 'idx', size: 1 },
				{ title: '작성일자', key: 'createDt', size: 3 },
				{ title: '핫트 아이디', key: 'hottId', size: 2 },
				{ title: '주문번호', key: 'orderNum', size: 2 },
				{ title: 'IDX', key: 'productGroupIdx', size: 1 },
				{ title: '파트너사', key: 'companyName', size: 2 },
				{ title: '브랜드', key: 'brandName', size: 2 },
				{ title: '상품명', key: 'productGroupName', size: 3, notCenter: 1 },
				{ title: '옵션명', key: 'productOptionName', size: 2, notCenter: 1 },
				{ title: '별점', key: 'reviewScore', size: 1 },
				{ title: '도움 수', key: 'likeCount', size: 1 },
				{ title: '상품 리뷰', key: 'reviewContent', size: 6, notCenter: 1 },
				{ title: '리뷰<br>글자수', key: 'reviewContentCount', size: 2 },
				{ title: '추가 의견', key: 'additionalComment', size: 3, notCenter: 1 },
				{ title: '의견<br>글자수', key: 'additionalCommentCount', size: 2 },
				{ title: '신고접수', key: 'claimCount', size: 1 },
				{ title: '신고 사유', key: 'reasonType', size: 2 },
			],
			lastOpt: {},
			res: {},
		}
	},
	methods: {
		search( operation = 'LIKE' ) {
		},
	},
	created() {
		postApi('member/t');
		this.search()
	},
}
</script>
